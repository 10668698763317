import { Alert, Typography, Dialog, DialogContent, DialogTitle, Fab, List, ListItem } from '@mui/material';
import { useMapsLibrary } from '@vis.gl/react-google-maps';
import { Map, Marker } from '@vis.gl/react-google-maps';
import { useCallback, useEffect, useState } from 'react';

interface State {
  lat: number | null;
  lng: number | null;
  status: 'MOVING' | 'OFF' | 'LOADING'

}

export default () => {
  const [isOpen, setIsOpen] = useState(false);

  const [state, setState] = useState<State>({
    lat: null,
    lng: null,
    status: 'LOADING'
  })

  const core = useMapsLibrary('core');

  const subscribe = useCallback(() => {
    fetch(`${process.env.REACT_APP_API_URL!}/location`).then(response => response.json())
      .then(data => setState(s => ({
        ...s,
        ...data,
        status: data.lat > 0 && data.lng > 0 ? 'MOVING' : 'OFF'
      }))).then(() => new Promise(resolve => setTimeout(resolve, 5000)))
      .catch(() => setState(s => ({ ...s, status: 'OFF' })))
      .finally(subscribe)
  }, [])

  useEffect(() => {
    subscribe();
    // @ts-ignore

    setState(s => ({
      ...s,
      lat: 25.050140403689678,
      lng: 46.90465836511352
    }))
  }, [subscribe])

  const handleToggle = useCallback(() => setIsOpen(!isOpen), [isOpen])

  return (
    <>

      <Map defaultCenter={{ lat: 24.75685176407977, lng: 46.6959100859814 }} defaultZoom={12} disableDefaultUI={true}>
        {state.lat && state.lng && core && (
          <Marker position={{ lat: state.lat, lng: state.lng }} icon={{ url: "/cybertruck.png", anchor: new google.maps.Point(48, 30) }} clickable={false} />
        )}
      </Map>
      <Fab color="primary" aria-label="info" onClick={handleToggle} style={{ position: 'fixed', bottom: 30, right: 30 }}>
        ℹ️
      </Fab>
      <Dialog onClose={handleToggle} open={isOpen}>
        <DialogTitle>Track Our Cybertruck in Real-Time! 🚀</DialogTitle>
        <DialogContent>
          <Typography>
            <Typography component="span" sx={{ fontWeight: 'bold' }}>Ready to win some epic rewards? 🎉</Typography><br/>
            Here's how to get in on the action:
          </Typography>
          <Typography component="ol" sx={{ marginY: 1 }}>
            <Typography component="li">Spot our Cybertruck in your area.</Typography>
            <Typography component="li">Snap a photo or take a video of the Cybertruck.</Typography>
            <Typography component="li">Share it on Instagram, tag us @<Typography component="span" sx={{ fontWeight: 'bold' }}>calo.ksa</Typography></Typography>
          </Typography>
          <Typography>The most creative entries will score some seriously cool prizes! So get out there, get snapping, and show us what you've got! 📸✨<br/>
            <Typography component="span" sx={{ fontWeight: 'bold', fontStyle: 'italic' }}>Pro tip: Make sure your Instagram account is public so we can see your awesome entries.</Typography>
          </Typography>
        </DialogContent>
      </Dialog>
      {state.status === 'OFF' && (
        <Alert icon="🪫" severity="info" style={{ position: 'fixed', top: 0, left: 0, right: 0 }}>
          The truck is rechargin, please come back later
        </Alert>
      )}
    </>
  );
}