import '@fontsource/lato/100.css';
import '@fontsource/lato/300.css';
import '@fontsource/lato/400.css';
import '@fontsource/lato/700.css';
import '@fontsource/lato/900.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './style.css'
import { APIProvider } from '@vis.gl/react-google-maps';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme';


const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY!}>
        <App />
      </APIProvider>
    </ThemeProvider>
  </React.StrictMode>
);