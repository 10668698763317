import { createTheme } from '@mui/material';

export const  theme = createTheme({
  typography: {
    allVariants: {
      lineHeight: 1.5,
    },
    fontSize: 16,
    fontFamily: 'Lato',
    fontWeightBold: 900,
    fontWeightRegular: 400,
    fontWeightLight: 300,
  },
  // breakpoints: {
  //   values: {
  //     xs: 0,
  //     sm: 640,
  //     md: 768,
  //     lg: 1026,
  //     xl: 1280,
  //   }
  // },
  // components: {
  //   MuiAlert: {
  //     styleOverrides: {
  //       message: {
  //         fontFamily: 'Lato',
  //         fontSize: 18,
  //         alignItems: 'center',
  //         display: 'flex',
  //       }
  //     }
  //   }
  // },
  palette: {
    primary: {
      main: '#57AE7F',
    },
  }
});